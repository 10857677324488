<template>
  <home-view></home-view>
</template>

<script>
import HomeView from "@/views/HomeView.vue";
export default {
  components: {
    HomeView
  }
}
</script>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.css';
@import '@/assets/styles/fonts.css';
body.blockScroll {
  overflow: hidden;
}
</style>
